














import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { BannerStores } from '@/store/banners';
import CustomEventForm from '@/components/custom-events/CustomEventForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    CustomEventForm,
  },
})
export default class EditBanners extends Vue {
  bannerStore = BannerStores;

  get banner() {
    return this.bannerStore?.banner;
  }

  mounted() {
    this.bannerStore.getBanner(Number(this.$route.params.id));
  }

  handleSubmit(payload: any) {
    this.bannerStore.updateBanner({
      id: Number(this.$route.params.id),
      ...payload,
    });
  }
}
